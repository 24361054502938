import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Panel from './Panel';
import MyCollection from './MyCollection';
import reportWebVitals from './reportWebVitals';
import Mint from './Mint';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
  {
    element: <App />,
    children:[
      {
        path:"/",
        element: <Mint/>
      },
      {
        path: "/panel",
        element: <Panel />,
      },
      {
        path: "/collections",
        element: <MyCollection />,
      },
    ]
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();