import './App.css';
import nft from "./images/NFT/nft.gif";
import slider1 from './images/NFT/1.png'
import slider2 from './images/NFT/2.png'
import slider3 from './images/NFT/3.png'
import slider4 from './images/NFT/4.png'
import slider5 from './images/NFT/5.png'
import slider6 from './images/NFT/6.png'

{/*<img src={nft}></img>*/}




function App() {

  return (
    <div className="info-container">
      <h2>BABY ALIENS</h2>

      <div className='info-showcase'>
        <h3>What is Baby Aliens collection about? </h3>
        <p>BABY ALIENS is a collection of 5.000 uniquely generated NFTs that'll be minted on Cronos Chain. Each NFT has different attributes with different rarities. A BABY ALIEN is also the key of the AFE organization's other projects. Our current goal is to develop AFE The Game & release it together with Upgrade NFTs that will allow players to earn valuables with systems we're going to develop.</p>
        <br></br>
        <h3>What will I get as a holder?  </h3>
        <li>Holders will have a chance to get a free upgrade their NFTs to our main collection in the near future.</li> 
        <li>Holders will be able to receive 5% royalty on our secondary sales.</li> 
        <li>Holders will have a say in all our projects and can directly influence the future of the ecosystem we'll build together.</li> 
        <li>Holders will have a chance to create special NPC characters for our games.</li> 
        <br></br>
      </div>

      <div>
      <div className='nfts-container'>
        <img src={slider1}></img>
        <img src={slider2}></img>
        <img src={slider3}></img>
        <img src={slider4}></img>
        <img src={slider5}></img>
        <img src={slider6}></img>
      </div>
</div>

    </div>
  );
}

export default App;
