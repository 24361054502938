import { EthereumClient, modalConnectors } from "@web3modal/ethereum";
import { Web3Modal, Web3Button, useWeb3Modal } from "@web3modal/react";
import { configureChains, createClient, getAccount, watchAccount } from "@wagmi/core";
import { publicProvider } from '@wagmi/core/providers/public';
import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { localNet, cronosMainnet, cronosTestnet } from "./Chains";
import { RockXProvider, AFEProvider, VVSProvider } from "./RPC";
import './App.css';
import vidBG from './images/Slower.mp4'
import Info from './Info'

import mintbutton from './images/mint.png'
import mycbutton from './images/myc.png'
import thelabutton from './images/lab-disabled.png'
import closebutton from './images/close-button.png'
import bottombg from './images/bottom.png'


const chains = [cronosMainnet];
const projectId = "049d0d460cbc1537aca58aee3d40ed73";


// Wagmi client
const { provider } = configureChains(chains, [
  publicProvider(),
  AFEProvider(),
  RockXProvider(),
  VVSProvider(),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [isConnected, setConnected] = useState<boolean>(false);
  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useWeb3Modal();
  const [mobileMenu, setMobileMenu] = useState<{display:string}>({display:'none'})

  useEffect(() => {
    const account = getAccount();
    setConnected(account.isConnected);

    if (account.address) {
      setWalletAddress(account.address.toString());
    }

    watchAccount((account) => {
      setConnected(account.isConnected);
  
      if (account.address) {
        setWalletAddress(account.address.toString());
      }
    });
  }, []);

  function onConnectClick() {
    if (isModalOpen) {
      closeModal();
    } else {
      openModal();
    }
  }

  function openMobileMenu(){
    setMobileMenu({display:'block'})
  }
  function closeMobileMenu(){
    setMobileMenu({display:'none'})
  }

  return (
    <div className="App">
      <div style={mobileMenu} className="mobile-menu">
      <ul>
        {isConnected && 
          <>
          <li>
            <NavLink
              to="/"
              onClick={closeMobileMenu}
              className={({ isActive, isPending }) =>
                isActive
                  ? "active-menu"
                  : isPending
                  ? "pending-menu"
                  : ""
              }
            >
              <img width={'100%'} src={mintbutton}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/collections"
              onClick={closeMobileMenu}
              className={({ isActive, isPending }) =>
                isActive
                  ? "active-menu"
                  : isPending
                  ? "pending-menu"
                  : ""
              }
            >
              <img width={'100%'} src={mycbutton}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={closeMobileMenu}
              to="#"
              className={"disabled-button"}
            >
              <img width={'100%'} src={closebutton}/>
            </NavLink>
          </li>
          </>
}
        </ul>

      </div>
      <div className="stars">
        <video autoPlay loop muted>
        <source src={vidBG} type="video/mp4" />
        </video>
      </div>
      <header id="header" style={{position:'relative', width:'%100', height:80}}>
        <div className="header-left" style={{width:'100%'}}></div>

        {isConnected && <button className="mobile-button" onClick={openMobileMenu}></button>}
        <ul className="header-menu">
        {isConnected && 
          <>
          <li className="mint-menu">
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isActive
                  ? "active-mint-menu"
                  : isPending
                  ? "pending-menu"
                  : ""
              }
            >
              <img width={'100%'} src={mintbutton}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/collections"
              className={({ isActive, isPending }) =>
                isActive
                  ? "active-menu"
                  : isPending
                  ? "pending-menu"
                  : ""
              }
            >
              <img width={'100%'} src={mycbutton}/>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              className={"disabled-button"}
            >
              <img width={'100%'} src={thelabutton}/>
            </NavLink>
          </li>
          </>
}
        </ul>

        {isConnected && <div style={{width:'100%', display:'flex', justifyContent:'flex-end'}}><button id='my-wallet' onClick={onConnectClick}></button></div>}
        {!isConnected && <div style={{width:'100%', display:'flex',justifyContent:'flex-end'}}><button id='connect-wallet' onClick={onConnectClick}></button></div>}
      </header>
      
      {isConnected ?  <Outlet />  : <Info/>}



      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
      />

      <img src={bottombg} id='bottom-bg'></img>
    </div>
  );
}

export default App;
