import {
  getNetwork,
  getAccount,
  prepareWriteContract,
  writeContract,
  waitForTransaction,
  readContract,
} from "@wagmi/core";
import { BigNumber, utils } from 'ethers';
import { getTokenConfig } from "./ContractConfig";


export function getWalletAddress() {
  const { address } = getAccount();

  return address;
}

export async function isPaused() {
  const { isConnected, address: walletAddress } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !walletAddress || !chain) {
    throw "Not connected.";
  }

  const { address, abi } = getTokenConfig(chain.name);
  const result = await readContract({
    address,
    abi,
    functionName: 'paused',
  }) as boolean;

  return result;
}

export async function getMaxMintCounts() {
  const { isConnected, address: walletAddress } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !walletAddress || !chain) {
    throw "Not connected.";
  }

  const { address, abi } = getTokenConfig(chain.name);
  const limits = await readContract({
    address,
    abi,
    functionName: 'getMintLimits',
    args: [
      walletAddress
    ]
  }) as BigNumber[];

  return {
    normal: (limits[0].toNumber()),
    sale: (limits[1].toNumber()),
    free: (limits[2].toNumber()),
  };
}

export async function getWLCount(walletAddress: string) {
  const { isConnected } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !chain) {
    throw "Not connected.";
  }

  const { address, abi } = getTokenConfig(chain.name);
  const count = await readContract({
    address,
    abi,
    functionName: 'getWhitelisted',
    args: [
      walletAddress
    ]
  }) as BigNumber;

  return count;
}

export async function getFMCount(walletAddress: string) {
  const { isConnected } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !chain) {
    throw "Not connected.";
  }

  const { address, abi } = getTokenConfig(chain.name);
  const count = await readContract({
    address,
    abi,
    functionName: 'getFreeMints',
    args: [
      walletAddress
    ]
  }) as BigNumber;

  return count;
}

export async function isController() {
  const { isConnected, address: walletAddress } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !walletAddress || !chain) {
    throw "Not connected.";
  }

  const { address, abi } = getTokenConfig(chain.name);
  const result = await readContract({
    address,
    abi,
    functionName: 'hasRole',
    args: [
      "0x7b765e0e932d348852a6f810bfa1ab891e259123f02db8cdcde614c570223357",
      walletAddress
    ]
  }) as Boolean;

  return result;
}

export async function getReserveLevels() {
  const { isConnected } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !chain) {
    throw "Not connected.";
  }

  const { address, abi } = getTokenConfig(chain.name);
  const limits = await readContract({
    address,
    abi,
    functionName: 'getReserveLevels',
  }) as BigNumber[];
  const supply = await readContract({
    address,
    abi,
    functionName: 'totalSupply',
  }) as BigNumber;

  return {
    minted: (supply.toNumber()),
    remaining: (limits[0].toNumber()),
    sale: (limits[1].toNumber()),
    free: (limits[2].toNumber()),
  };
}

export async function mintNFT(type: string, amount: number) {
  const { isConnected, address: walletAddress } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !walletAddress || !chain) {
    return {
      error: "Not connected.",
    };
  }

  const { address, abi, mintPrice, salePrice } = getTokenConfig(chain.name);
  const mintPriceEther = utils.parseEther(mintPrice);
  const salePriceEther = utils.parseEther(salePrice);
  let config;

  if (type == 'normal') {
    const payment = mintPriceEther.mul(amount);

    config = await prepareWriteContract({
      address,
      abi,
      functionName: 'mint',
      args: [
        amount
      ],
      overrides: {
        from: walletAddress,
        value: payment,
      }
    });
  }
  else if (type == 'sale') {
    const payment = salePriceEther.mul(amount);

    config = await prepareWriteContract({
      address,
      abi,
      functionName: 'mintSale',
      args: [
        amount
      ],
      overrides: {
        from: walletAddress,
        value: payment,
      }
    });
  }
  else if (type == 'free') {
    config = await prepareWriteContract({
      address,
      abi,
      functionName: 'mintFree',
      args: [
        amount
      ],
      overrides: {
        from: walletAddress,
      }
    });
  }
  else {
    return {
      error: "Invalid mint type.",
    };
  }

  const { hash } = await writeContract(config);

  const txReceipt = await waitForTransaction({
    hash,
  });

  if (!txReceipt) {
    return {
      error: "Transaction failed.",
    };
  }

  return {
    hash,
  };
}

export async function getTokenCount() {
  const { isConnected, address: walletAddress } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !walletAddress || !chain) {
    return 0;
  }

  const { address, abi } = getTokenConfig(chain.name);
  const tokenCount = await readContract({
    address,
    abi,
    args: [ walletAddress ],
    functionName: 'balanceOf',
  }) as BigNumber;

  return tokenCount.toNumber();
}

export async function getTokens() {
  const { isConnected, address: walletAddress } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !walletAddress || !chain) {
    return {
      error: "Not connected.",
    };
  }

  const { address, abi } = getTokenConfig(chain.name);
  const allTokens = await readContract({
    address,
    abi,
    args: [ walletAddress ],
    functionName: 'getTokensOf',
  }) as BigNumber[];
  const tokens = allTokens.map(x => x.toNumber());

  return {
    tokens,
  };
}

async function write(functionName: string, args: any[] | undefined = undefined) {
  const { isConnected, address } = getAccount();
  const { chain } = getNetwork();

  if (!isConnected || !address || !chain) {
    return {
      error: "Not connected.",
    };
  }

  const { address: contractAddress, abi } = getTokenConfig(chain.name);
  const config = await prepareWriteContract({
    address: contractAddress,
    abi,
    functionName,
    args: args,
    overrides: {
      from: address,
    }
  });
  const { hash } = await writeContract(config);
  const txReceipt = await waitForTransaction({ hash });

  if (!txReceipt) {
    return {
      error: "Transaction failed.",
    }
  }

  return {
    error: null,
  };
}

export async function setTokenBaseURI(baseURI: string) {
  return write('setBaseURI', [baseURI]);
}

export async function unpause() {
  return write('unPause');
}

export async function pause() {
  return write('pause');
}

export async function setReserved(amount: number) {
  return write('setReservedTokenCount', [amount]);
}

export async function giveFreeMulti(addresses: string[], amount: number) {
  return write('giveFreeMint', [addresses, amount]);
}

export async function removeFreeMulti(addresses: string[]) {
  return write('removeFreeMint', [addresses]);
}

export async function giveDiscountMulti(addresses: string[], amount: number) {
  return write('giveDiscount', [addresses, amount]);
}

export async function removeDiscountMulti(addresses: string[]) {
  return write('removeDiscount', [addresses]);
}