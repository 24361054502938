import {switchNetwork, watchAccount} from "@wagmi/core";
import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import defaultChain from "./Chains";
import { getMaxMintCounts, getTokenCount, getTokens, mintNFT, isPaused } from "./web3";
import { getTokenImageURL, getTokenMetadata } from "./babyaliens";

import './App.css';

type Trait = {
  trait_type: string,
  value: string,
}

function Mint() {
  // Input Values
  const [ mintAmount, setMintAmount ] = useState<number>(1);
  const [ mintType, setMintType ] = useState<string>("normal");

  // Chain State
  const [ mintPaused, setMintPaused ] = useState<boolean>(false);
  const [ maxNormalMint, setMaxNormalMint ] = useState<number>(0);
  const [ maxSaleMint, setMaxSaleMint ] = useState<number>(0);
  const [ maxFreeMint, setMaxFreeMint ] = useState<number>(0);

  // Application State
  const [ isMinting, setMinting ] = useState<boolean>(false);
  const [ isSuccess, setSuccess ] = useState<boolean>(false);
  const [ errorMessage, setErrorMessage ] = useState<string>("");

  // Results
  const [ mintedTokens, setMintedTokens ] = useState<number[]>([]);
  const [ txHash, setTxHash ] = useState<string>("");
  const [ displayToken, setDisplayToken ] = useState<number>(0);

  const [ traits, setTraits ] = useState<Array<Trait>>([]);


  
  useEffect(() => {
    updateMaxMint().catch(err => console.error(err));

    watchAccount((account) => {
      if (account.isConnected) {
        updateMaxMint().catch(err => console.error(err));
      }
    });
  }, []);

  useEffect(() => {
    if (!mintedTokens[displayToken]) {
      return;
    }

    setTraits(getTokenMetadata(mintedTokens[displayToken]));
  }, [mintedTokens[displayToken]]);


  async function updateMaxMint() {
    const { normal, sale, free } = await getMaxMintCounts();

    setMintPaused(await isPaused())
    setMaxNormalMint(normal);
    setMaxSaleMint(sale);
    setMaxFreeMint(free);
  }

  async function checkAmount(type: string, amount: number) {
    if (type == "sale") {
      if (amount > maxSaleMint) {
        return false;
      }
    }

    if (type == "free") {
      if (amount > maxFreeMint) {
        return false;
      }
    }
    return true;
  }


  async function mint(type: string, amount: number) {
    setMinting(true);
    setSuccess(false);
    setErrorMessage("");
    setIsPlaying(true);


    if (!checkAmount(type, amount)) {
      setMinting(false);
      setErrorMessage("Sorry, you cannot mint that many tokens.");
      return false;
    }

    const targetCount = await getTokenCount() + amount;
    const result = await mintNFT(type, amount);

    if (result.error) {
      setMinting(false);
      setErrorMessage(result.error);
      return;
    }

    if (result.hash) {
      setTxHash(result.hash);
      setSuccess(true);
      setMinting(false);
      setErrorMessage("");

      updateMaxMint().catch(err => console.error(err));
    }

    async function checkNewTokens() {
      const lastCount = await getTokenCount();

      if (lastCount < targetCount) {
        setTimeout(checkNewTokens, 1000);
        return;
      }
  
      const final = await getTokens();
  
      if (final.error) {
        setErrorMessage("Couldn't load minted tokens.");
        return;
      }
  
      if (final.tokens) {
        setMintedTokens(final.tokens.slice(-amount));
      }
    }

    checkNewTokens();
  }

  function createMintButtonClickEvent(type: string, amount: number) {
    return () => mint(type, amount).catch(err => {
      setMinting(false);
      
      if (err == "Wrong network!") {
        setErrorMessage("Wrong network!");

        switchNetwork({
          chainId: defaultChain.id,
        })
        .then(() => setErrorMessage(""))
        .catch(_ => setErrorMessage("An error occured."));
      } else {
        setErrorMessage("An error occured.");
      }

      console.error(err);
    });

  }

  function createMintAmountChangeEvent(setter: Dispatch<SetStateAction<number>>) {
    return (evt: React.FormEvent<HTMLInputElement>) => {
      const num = parseInt(evt.currentTarget.value);

      if (isNaN(num)) {
        setter(1);
      } else {
        setter(Math.min(Math.max(1, num), 20));
      }
    }
  }
 
  const [isPlaying, setIsPlaying] = useState(false);


  return (
    <div className="Mint">
      <div style={{color: "red"}}>{errorMessage}</div>

      {mintPaused &&
      <div>
        Sorry, minting is paused.
      </div>
      }

      {!mintPaused &&
      <div>
        <div className="select-container">
          <form>
            <input disabled={mintPaused || maxNormalMint < 1} defaultChecked type="radio" value="mint" name="mint-type" onChange={() => setMintType("normal")} />
            <label>MINT </label>

            <input disabled={mintPaused || maxSaleMint < 1} style={{ cursor: maxFreeMint < 1 ? "not-allowed" : "pointer"}} type="radio" value="wl" name="mint-type" onChange={() => setMintType("sale")}/>
            <label>WL </label>

            <input disabled={mintPaused || maxFreeMint < 1} style={{ cursor: maxFreeMint < 1 ? "not-allowed" : "pointer"}} type="radio" value="freemint" name="mint-type" onChange={() => setMintType("free")}/>
            <label>FREE MINT </label>
          </form>
        </div>
        <div className="button-container">
        <input className="number-input" type="number" name="quantity" pattern="[0-9]+" min="1" max="20" value={mintAmount} onChange={createMintAmountChangeEvent(setMintAmount)}/>
        <button style={{ animationPlayState: isPlaying ? "running" : "paused", margin: 5, padding: 5 }} onClick={createMintButtonClickEvent(mintType, mintAmount)} id='mint-button'>
          {isMinting ? <h2></h2> : <h2></h2>}
        </button>
        </div>
      </div>
      }

          {isSuccess && (


      <div className="details-page">
        <div className="details-button" style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
      {displayToken > 0 ?
        <button id="prev-button" onClick={() => setDisplayToken(displayToken-1)} ></button>
        : <div></div>
        }
        {displayToken < mintedTokens.length-1 ?
        <button id="next-button" onClick={() => setDisplayToken(displayToken+1)}></button>
        : <div></div>
        }
      </div>

    <div className="details-section">
        <div className="details-container">
        <img src={getTokenImageURL(mintedTokens[displayToken])}></img>

        <a href={`https://cronoscan.com/tx/${txHash}`}>View on Cronoscan</a>
        </div>

        <div className="right-details-container">
        <ul>
          <h3>#{mintedTokens[displayToken]}</h3>
          {traits.map(trait => <li><b>{trait.trait_type}:</b> {trait.value}</li>)}
        </ul>
        </div>
      </div>
      </div>
            )}

    </div>
  );
}

export default Mint;
