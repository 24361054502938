import contractAbi from './contractAbi.json'

const config = {
  "Private": {
    "address": "0x5FbDB2315678afecb367f032d93F642f64180aa3",
    "mintPrice": "199.0",
    "salePrice": "149.0",
    "abi": contractAbi,
  },
  "Cronos Testnet": {
    "address": "0x525b880227b7720DC5D4646D6713ce9EC12b9bB4",
    "mintPrice": "0.199",
    "salePrice": "0.149",
    "abi": contractAbi,
  },
  "Cronos": {
    "address": "0xF4a27cb06141c74638b861cD75Ef7237401ad345",
    "mintPrice": "199.0",
    "salePrice": "149.0",
    "abi": contractAbi,
  },
}

export function getTokenConfig(network: string | undefined) {
  if (network == "Private") {
    return config["Private"];
  }

  if (network == "Cronos Testnet") {
    return config["Cronos Testnet"];
  }

  if (network == "Cronos") {
    return config["Cronos"];
  }

  throw "Wrong network!";
}
