import { useEffect, useState } from 'react';
import { getTokens } from "./web3";
import { getTokenImageURL, getTokenMetadata } from "./babyaliens";
import { NavLink } from "react-router-dom";
import closebutton from './images/close-button.png'


import './App.css';


type Trait = {
  trait_type: string,
  value: string,
}

function MyCollection() {
  const [ errorMessage, setErrorMessage ] = useState<string>("");
  const [ tokens, setTokens ] = useState<number[]>([]);
  const [ selectedToken, setSelectedToken ] = useState<number>(0);
  const [ traits, setTraits ] = useState<Array<Trait>>([]);
  const [traitsModal, setTraitsModal] = useState<{display:string}>({display:'none'})

  useEffect(() => {
    getTokens().then((data) => {
      if (data.error) {
        setErrorMessage("Couldn't load your collection.");
        return;
      }

      if (data.tokens) {
        setTokens(data.tokens);
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedToken) {
      return;
    }

    setTraits(getTokenMetadata(selectedToken));
    setTraitsModal({display:'flex'});
  }, [selectedToken]);

  function closeModal(){
    setTraitsModal({display:'none'})
  }

  return (
    <div className='collections-page'>
      <div style={traitsModal} className='traits-modal'>
          <div className='modal-container'>
            <h2>TRAITS</h2>
              <div className='traits'>
                {traits.map(trait => <p><b>{trait.trait_type}:</b> {trait.value}</p>)}
              </div>
              <a onClick={closeModal}><img width={'100%'} src={closebutton}/></a>
          </div>
      </div>

      {tokens.length < 1 && <p>You don't have any Baby Alien, <NavLink to="/">click here</NavLink> to get a Baby Alien.</p>}
      
        <div className='collections-container'>
          <div style={{color: "red"}}>{errorMessage}</div>
          <br />
          <br />
          {tokens.length &&
            <div className='collection-items'>
              {tokens.map(id =>
                <p>#{id}<br /><img src={getTokenImageURL(id)} width="256" onClick={() => setSelectedToken(id)} /></p>
              )}  
            </div>}
        </div>
    </div>
  );
}

export default MyCollection;
