import { useEffect, useState, createRef, RefObject } from 'react';

import { watchAccount } from "@wagmi/core";
import {
  setTokenBaseURI, isController,
  getWalletAddress,
  pause, unpause, isPaused,
  setReserved, getReserveLevels,
  giveFreeMulti, giveDiscountMulti,
  removeDiscountMulti, removeFreeMulti,
  getFMCount, getWLCount
} from "./web3";


function Panel() {
  const [ reserves, setReserves ] = useState<{minted: number, remaining: number, sale: number, free: number}>();
  const [ mintingPaused, setMintingPaused ] = useState<string>("-");
  const [ shouldHide, setShouldHide ] = useState<boolean>(true);
  const [ currentWallet, setCurrentWallet ] = useState<string>("-");


  useEffect(() => {
    setCurrentWallet(getWalletAddress() as string);
    isController().then(correct => {
      setShouldHide(!correct);
    });

    watchAccount((account) => {
      setCurrentWallet(account.address as string);

      if (account.isConnected) {
        isController().then(correct => {
          setShouldHide(!correct);
        });
      }
    });

    isPaused().then(paused => setMintingPaused(paused ? "PAUSED" : "MINTING"));

    getReserveLevels().then(levels => {
      setReserves(levels);
    });
  }, []);


  const walletAddrForWLCheck = createRef<HTMLInputElement>();
  const walletAddrForWLSet = createRef<HTMLTextAreaElement>();
  const amountForWLSet = createRef<HTMLInputElement>();

  const walletAddrForFMCheck = createRef<HTMLInputElement>();
  const walletAddrForFMSet = createRef<HTMLTextAreaElement>();
  const amountForFMSet = createRef<HTMLInputElement>();

  const baseURI = createRef<HTMLInputElement>();
  const reservedCount = createRef<HTMLInputElement>();

  function getTextValue(ref: RefObject<HTMLInputElement>) {
    return ref.current ? ref.current.value : "";
  }

  function getTextValueMulti(ref: RefObject<HTMLTextAreaElement>) {
    const value = ref.current ? ref.current.value : "";

    return value.split('\n').filter(Boolean).map(addr => addr.trim());
  }

  function getIntValue(ref: RefObject<HTMLInputElement>) {
    return ref.current ? parseInt(ref.current.value) : -1;
  }


  const [ checkResultWL, setCheckResultWL ] = useState<string>("");
  const [ checkResultFM, setCheckResultFM ] = useState<string>("");
  const [ result, setResult ] = useState<string>("");

  async function checkResultForError(resultPromise: Promise<{error: string | null}>) {
    const result = await resultPromise;

    if (result.error) {
      setResult(result.error);
      return;
    }

    setResult("Success!");
  }

  function callAndCatch(fn: Function) {
    return () => fn().catch((err: Error) => setResult(err.message));
  }


  async function onCheckWL() {
    const addr = getTextValue(walletAddrForWLCheck);
    const count = await getWLCount(addr);

    setCheckResultWL(addr + " = " + count.toString());
  }

  function onSendWL() {
    const amount = getIntValue(amountForWLSet);
    const wallets = getTextValueMulti(walletAddrForWLSet);

    if (amount === 0) {
      return checkResultForError(removeDiscountMulti(wallets));
    }

    return checkResultForError(giveDiscountMulti(wallets, amount));
  }

  async function onCheckFM() {
    const addr = getTextValue(walletAddrForFMCheck);
    const count = await getFMCount(addr);

    setCheckResultFM(addr + " = " + count.toString());
  }

  function onSendFree() {
    const amount = getIntValue(amountForFMSet);
    const wallets = getTextValueMulti(walletAddrForFMSet);

    if (amount === 0) {
      return checkResultForError(removeFreeMulti(wallets));
    }

    return checkResultForError(giveFreeMulti(wallets, amount));
  }

  function onSetReserved() {
    return checkResultForError(setReserved(
      getIntValue(reservedCount)
    ));
  }

  function onSetBaseURI() {
    return checkResultForError(setTokenBaseURI(
      getTextValue(baseURI)
    ));
  }


  async function onPause() {
    const result = await pause();

    if (result.error) {
      setResult(result.error);
      return;
    }

    setResult("Success!");
  }

  async function onUnpause() {
    const result = await unpause();

    if (result.error) {
      setResult(result.error);
      return;
    }

    setResult("Success!");
  }

  return (
    <div className="Mint" style={{padding: "2em"}}>
      <div style={{color: "orange"}}>{result}</div>

      <h2>Welcome</h2>

      <p>{currentWallet}</p>


      {!shouldHide &&
      <div>
        <h2>Reserves</h2>

        <p>Minted Tokens: {reserves?.minted || "-"}</p>
        <p>Remaining Reserved Tokens: {reserves?.remaining || "-"}</p>
        <p>Total Sale Tokens: {reserves?.sale || "-"}</p>
        <p>Total Free Tokens: {reserves?.free || "-"}</p>


        <h2>Whitelist</h2>

        <p>
          <span style={{color: "orange"}}>{checkResultWL}</span><br />
          <input placeholder="Wallet Address" type="text" ref={walletAddrForWLCheck} className="panel-input" />
          <button onClick={callAndCatch(onCheckWL)} className="panel-button">Check</button>
        </p>

        <p>
          <textarea placeholder="Wallet Addresses" ref={walletAddrForWLSet} className="panel-input" />
          <input placeholder="Amount" type="number" min="0" ref={amountForWLSet} className="panel-input" />
          <small>Amount = 0 to remove</small><br />
          <button onClick={callAndCatch(onSendWL)} className="panel-button">Set Whitelisted</button>
        </p>


        <h2>FREE Mint</h2>

        <p>
          <span style={{color: "orange"}}>{checkResultFM}</span><br />
          <input placeholder="Wallet Address" type="text" ref={walletAddrForFMCheck} className="panel-input" />
          <button onClick={callAndCatch(onCheckFM)} className="panel-button">Check</button>
        </p>

        <p>
          <textarea placeholder="Wallet Addresses" ref={walletAddrForFMSet} className="panel-input" />
          <input placeholder="Amount" type="number" min="0" ref={amountForFMSet} className="panel-input" />
          <small>Amount = 0 to remove</small><br />
          <button onClick={callAndCatch(onSendFree)} className="panel-button">Set Free Minter</button>
        </p>


        <h2>Minting State</h2>

        <p>Currently {mintingPaused}</p>
        <p>
          <button onClick={callAndCatch(onPause)} className="panel-button">Pause</button>
          <button onClick={callAndCatch(onUnpause)} className="panel-button">Unpause</button>
        </p>


        <h2>Other</h2>

        <p>
          <input placeholder="Count" type="number" min="0" ref={reservedCount} className="panel-input" />
          <button onClick={callAndCatch(onSetReserved)} className="panel-button">Set Reserved Token Count</button>
        </p>

        <p>
          <input placeholder="Base URI" type="text" ref={baseURI} className="panel-input" />
          <button onClick={callAndCatch(onSetBaseURI)} className="panel-button">Set Token URL</button>
        </p>

      </div>}
    </div>
  );
}

export default Panel;