import CharactersData from "./characters.json";

const s3bucket = "https://metadata.aliensfromearth.com/babyaliens/";

export function getTokenImageURL(tokenId: number) {
    return s3bucket + tokenId + ".png";
}

export function getTokenMetadata(tokenId: number) {
    const traits = CharactersData.characters[tokenId];
    return traits.map((traitIndex, typeIndex) => ({
        "trait_type": CharactersData.type_names[typeIndex],
        "value": traitIndex ? CharactersData.trait_names[typeIndex][traitIndex] : "",
    })).filter(x => x["value"]);
}